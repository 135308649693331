<template>
  <div class="destination__stars">
    <svg
      class="destination__star"
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="25"
      viewBox="0 0 26 25"
      v-for="num in starNumber*1"
      :key="num"
    >
      <defs:style>
        .star-dark {
        fill: #002036;
        }
      </defs:style>
      <path
        class="star-dark"
        d="M32.158.857,28.97,7.321l-7.133,1.04a1.563,1.563,0,0,0-.864,2.666l5.161,5.029-1.221,7.1A1.561,1.561,0,0,0,27.178,24.8l6.381-3.354L39.941,24.8a1.563,1.563,0,0,0,2.265-1.645l-1.221-7.1,5.16-5.029a1.563,1.563,0,0,0-.864-2.666l-7.133-1.04L34.961.857a1.564,1.564,0,0,0-2.8,0Z"
        transform="translate(-20.5 0.013)"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'RateStar',
  props: {
    starNumber: [Number, String]
  }
}
</script>

<style lang="scss" scoped>
.destination__stars {
  height: 22px;
}
</style>